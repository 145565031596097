import 'takeover/takeover.js';
import lazySizes from 'lazysizes';

lazySizes.cfg.iframeLoadMode = 1;

// Hack to replace burst-icon-with-exclamation-point with burst-icon-NO-exclamation-point
// for DOE icon when number of events is also displayed.
$(document).ready(() => {
  const $span = $('.js_days_count');
  if ($span.length) {
    $span.hide();
    window.setTimeout(() => {
      if ($span.html() !== '!') {
        const val = $span.prev('.icon').find('use').attr('xlink:href');
        $span.prev('.icon').find('use').attr(
          'xlink:href',
          val.replace('sbf-icon-burst', 'sbf-icon-burst-blank').replace('icon_burst', 'sbf-icon-burst-blank')
        );
        $span.show();
      }
    }, 500);
  }
});
